import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion"

const Teaser = ({ post, index }) => {
  const title = post.frontmatter.title || post.fields.slug
  const subtitle = post.frontmatter.subtitle
  const cover = post.frontmatter.cover.childImageSharp.gatsbyImageData
  return (
    <article
      className={
        (index ^ 1) === index + 1
          ? "col-span-7 col-start-1"
          : "col-span-7 col-start-2"
      }
    >
      <Link to={post.fields.slug} className="grid grid-cols-7 gap-6 ">
        <div
          className={
            (index ^ 1) === index + 1
              ? "order-1 col-span-7 sm:col-span-3"
              : "order-2 col-span-7 sm:col-span-3"
          }
        >
          <motion.div magic magicid="image">
            <GatsbyImage image={cover} alt={title} className="aspect-w-16 aspect-h-9" />
          </motion.div>
        </div>
        <div
          className={
            (index ^ 1) === index + 1
              ? "flex justify-end flex-col items-start order-2 col-span-7 sm:col-span-4 text-left"
              : "flex flex-col items-end justify-end order-1 col-span-7 sm:col-span-4 text-right"
          }
        >
          <header className="max-w-xl">
            {subtitle && (
              <div className="font-mono no-underline uppercase font-2xl">
                {subtitle}
              </div>
            )}
            <div className="font-mono text-2xl no-underline ">{title}</div>
            {/* <small>{post.frontmatter.date}</small> */}
          </header>
          {/*
              <section>
                <p
                  className="mb-8 "
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                />
              </section>
              */}
        </div>
      </Link>
    </article>
  );
}

export default Teaser


export const query = graphql`fragment TeaserFragment on MarkdownRemark {
  excerpt
  fields {
    slug
  }
  frontmatter {
    date(formatString: "MMMM DD, YYYY")
    title
    subtitle
    description
    cover {
      childImageSharp {
        gatsbyImageData(height: 500, quality: 90, layout: FULL_WIDTH)
      }
    }
  }
}
`